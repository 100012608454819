function smoothScroll(pageupSelector, numYOffset) {

    // Smooth scroll and pageup

    const pageup = document.querySelector(pageupSelector);

    window.addEventListener('scroll', () => {
        if (window.pageYOffset > numYOffset) {
            pageup.classList.add('show');
            pageup.classList.remove('hide');
            if (location.pathname === '/learning') {
                pageup.classList.add('showcenter');
            }
        } else {
            pageup.classList.remove('show');
            pageup.classList.remove('showcenter');
            pageup.classList.add('hide');

        }
    });

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();

            const targetId = this.getAttribute('href');
            const targetElement = document.querySelector(targetId);

            targetElement.scrollIntoView({
                behavior: 'smooth'
            });

            // add hash after scroll
            history.pushState(null, null, targetId);
        });
    });
}

export default smoothScroll;
