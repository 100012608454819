function showModalByScroll() {
    if (window.pageYOffset + document.documentElement.clientHeight >= 0.98 * document.documentElement.scrollHeight) {
        openModal('.modal', 1);
    }
}

function openModal(modalSelector, modalTimerId) {
    const modal = document.querySelector(modalSelector);

    modal.classList.add('show');
    modal.classList.remove('hide');
    document.body.style.overflow = 'hidden';
    window.removeEventListener('scroll', showModalByScroll);

    // console.log(modalTimerId);
    if (modalTimerId) {
        clearInterval(modalTimerId);
    }

}

function closeModal(modalSelector) {
    const modal = document.querySelector(modalSelector);

    modal.classList.remove('show');
    modal.classList.add('hide');
    document.body.style.overflow = '';
}

function modal(triggerSelector, modalSelector, modalTimerId) {

    // Modal

    const modalTrigger = document.querySelector(triggerSelector),
          modal = document.querySelector(modalSelector);


    modalTrigger.addEventListener('click', () => {
        openModal(modalSelector, modalTimerId);
    });

    modal.addEventListener('click', (e) => {
        const target = e.target;
        if (target === modal || target.getAttribute('data-close') == '') {
            closeModal(modalSelector);
        }
    })

    document.addEventListener('keydown', (e) => {
        if (e.code === 'Escape' && modal.classList.contains('show')) {
            closeModal(modalSelector);
        }
    });


    window.addEventListener('scroll', showModalByScroll);

}

export default modal;
export {openModal, closeModal};